* {
  padding: 0;
  margin: 0;

  box-sizing: border-box;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid rgb(189, 189, 189);
  border-bottom: 10px solid rgb(189, 189, 189);
  border-right: 10px solid rgb(66, 66, 66);
  border-left: 10px solid rgb(66, 66, 66);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
